// ** Third Party Components
import axios from '../../../services/api'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

// Função da aba cursos
export const getCourses = createAsyncThunk('appEad/getCourses', async () => {
  const response = await axios.get('/admin/course/list')

  return {
    data: response.data.courses,
    totalPages: response.data.courses.length
  }
})

// Função de leitura dos dados e um elemento específico
export const getCourse = createAsyncThunk('appEad/getCourse', async id => {
  const response = await axios.get(`/admin/course/${id}`)
  return response.data.course
})

// Função de adição de novos cursos
export const addCourse = createAsyncThunk('appEad/addCourse', async params => {
  const response = await axios.post(`/admin/course`, params)
  return response
})

// Função de edição de cursos
export const editCourse = createAsyncThunk('appEad/editCourse', async params => {
  const response = await axios.post(`/admin/course/${params.id}`, params)
  return response
})

// Função de remoção de cursos
export const deleteCourse = createAsyncThunk('appEad/deleteCourse', async (id, { dispatch, getState }) => {
  await axios.delete(`/admin/course/${id}`)
  await dispatch(getCourses(getState().ead.params))
  return id
})

// Função da aba de fases
export const getUnits = createAsyncThunk('appEad/getUnits', async params => {
  const response = await axios.get(`/admin/unity/list/${params.courseId}/${params.page}`, params)
  return {
    data: response.data.units,
    details: response.data.details,
    totalPages: response.data.total
  }
})

// Função de leitura dos dados e um elemento específico
export const getUnity = createAsyncThunk('appEad/getUnity', async id => {
  const response = await axios.get(`/admin/unity/${id}`)
  return response.data.unity
})

// Função de adição de novos cursos
export const addUnity = createAsyncThunk('appEad/addUnity', async params => {
  const response = await axios.post(`/admin/unity`, params, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
  return response
})

// Função de edição de cursos
export const editUnity = createAsyncThunk('appEad/editUnity', async params => {
  const response = await axios.post(`/admin/unity/${params.id}`, params, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
  return response
})

export const editUnityOrder = createAsyncThunk('appEad/editUnityOrder', async params => {
  const response = await axios.post(`/admin/unity/order/${params.id}`, params)
  return response
})

// Função de remoção de cursos
export const deleteUnity = createAsyncThunk('appEad/deleteUnity', async (id, { dispatch, getState }) => {
  await axios.delete(`/admin/unity/${id}`)
  await dispatch(getUnits(getState().ead.params))
  return id
})

// Função da aba de fases
export const getLessons = createAsyncThunk('appEad/getLessons', async params => {
  const response = await axios.get(`/admin/lesson/list/${params.unityId}/${params.page}`, params)
  return {
    data: response.data.lessons,
    details: response.data.details,
    totalPages: response.data.total
  }
})

// Função da aba de fases
export const getSubLessons = createAsyncThunk('appEad/getSubLessons', async params => {
  const response = await axios.get(`/admin/lesson/sublist/${params.unityId}/${params.lessonId}`, params)
  return {
    data: response.data.lessons,
    details: response.data.details,
    totalPages: response.data.total
  }
})

// Função da aba de fases
export const getMicroLessons = createAsyncThunk('appEad/getMicroLessons', async params => {
  const response = await axios.get(`/admin/lesson/microlist/${params.lessonId}`, params)
  return {
    data: response.data.lessons,
    details: response.data.details,
    totalPages: response.data.total
  }
})

// Função de leitura dos dados e um elemento específico
export const getLesson = createAsyncThunk('appEad/getLesson', async id => {
  const response = await axios.get(`/admin/lesson/${id}`)
  return response.data.lesson
})

// Função de adição de novos cursos
export const addLesson = createAsyncThunk('appEad/addLesson', async params => {
  const response = await axios.post(`/admin/lesson`, params, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
  return response
})

// Função de edição de cursos
export const editLesson = createAsyncThunk('appEad/editLesson', async params => {
  const response = await axios.post(`/admin/lesson/${params.id}`, params, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
  return response
})

// Função de remoção de cursos
export const deleteLesson = createAsyncThunk('appEad/deleteLesson', async (id, { dispatch, getState }) => {
  await axios.delete(`/admin/lesson/${id}`)
  await dispatch(getLessons(getState().ead.params))
  return id
})

// Função da aba dos blocos das fases
export const getPages = createAsyncThunk('appEad/getPages', async params => {
  const response = await axios.get(`/admin/course/page/list/${params.lessonId}/${params.page}`)
  return {
    data: response.data.pages,
    details: response.data.details,
    totalPages: response.data.total
  }
})

export const getPagesSearch = createAsyncThunk('appEad/getPagesSearch', async params => {
  const response = await axios.get(`/admin/course/page/search/${params.lessonId}/${params.type}/${params.status}/${params.search}/${params.sort}/${params.sortColumn}/${params.page}/${params.numberPerPage}`)
  return {
    data: response.data.pages,
    details: response.data.details,
    totalPages: response.data.total
  }
})

// Função de leitura dos dados e um elemento específico
export const getPage = createAsyncThunk('appEad/getPage', async id => {
  const response = await axios.get(`/admin/course/page/${id}`)
  return response.data.page
})

// Função de adição de novos cursos
export const addPage = createAsyncThunk('appEad/addPage', async params => {
  const response = await axios.post(`/admin/course/page`, params, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
  return response
})

// Função de edição de cursos
export const editPage = createAsyncThunk('appEad/editPage', async params => {
  const response = await axios.post(`/admin/course/page/${params.id}`, params, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
  return response
})

// Função de edição de cursos
export const editPageOrder = createAsyncThunk('appEad/editPageOrder', async params => {
  const response = await axios.post(`/admin/course/page/order/${params.id}`, params)
  return response
})

// Função de remoção de cursos
export const deletePage = createAsyncThunk('appEad/deletePage', async (id, { dispatch, getState }) => {
  await axios.delete(`/admin/course/page/${id}`)
  await dispatch(getPages(getState().ead.params))
  return id
})

// Função da aba dos blocos das fases
export const getQuestions = createAsyncThunk('appEad/getQuestions', async params => {
  const response = await axios.get(`/admin/question/list/${params.lessonId}/${params.page}`)
  return {
    data: response.data.questions,
    details: response.data.details,
    totalPages: response.data.total
  }
})

export const getQuestionsSearch = createAsyncThunk('appEad/getQuestionsSearch', async params => {
  const response = await axios.get(`/admin/question/search/${params.lessonId}/${params.type}/${params.status}/${params.search}/${params.sort}/${params.sortColumn}/${params.page}/${params.numberPerPage}`)
  return {
    data: response.data.questions,
    details: response.data.details,
    totalPages: response.data.total
  }
})

// Função de leitura dos dados e um elemento específico
export const getQuestion = createAsyncThunk('appEad/getQuestion', async id => {
  const response = await axios.get(`/admin/question/${id}`)
  return response.data.question
})

// Função de adição de novos cursos
export const addQuestion = createAsyncThunk('appEad/addQuestion', async params => {
  const response = await axios.post(`/admin/question`, params, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
  return response
})

// Função de edição de cursos
export const editQuestion = createAsyncThunk('appEad/editQuestion', async params => {
  const response = await axios.post(`/admin/question/${params.id}`, params, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
  return response
})

export const editQuestionOrder = createAsyncThunk('appEad/editQuestionOrder', async params => {
  const response = await axios.post(`/admin/question/order/${params.id}`, params)
  return response
})

// Função de remoção de cursos
export const deleteQuestion = createAsyncThunk('appEad/deleteQuestion', async (id, { dispatch, getState }) => {
  await axios.delete(`/admin/question/${id}`)
  await dispatch(getQuestions(getState().ead.params))
  return id
})

// Função de remoção de respostas de questão
export const deleteQuestionAnswer = createAsyncThunk('appEad/deleteQuestionAnswer', async id => {
  const response = await axios.delete(`/admin/answer/${id}`)
  return response
})

// Função de criação dos slices
export const appSlice = createSlice({
  name: 'appEad',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: []
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getCourses.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getCourse.fulfilled, (state, action) => {
        state.selectedCourse = action.payload
      })
      .addCase(addCourse.fulfilled, (state, action) => {
        state.result = action.payload
      })
      .addCase(editCourse.fulfilled, (state, action) => {
        state.result = action.payload
      })
      .addCase(deleteCourse.fulfilled, (state, action) => {
        state.result = action.payload
      })
      .addCase(getUnits.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.details = action.payload.details
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getUnity.fulfilled, (state, action) => {
        state.selectedUnity = action.payload
      })
      .addCase(addUnity.fulfilled, (state, action) => {
        state.result = action.payload
      })
      .addCase(editUnity.fulfilled, (state, action) => {
        state.result = action.payload
      })
      .addCase(editUnityOrder.fulfilled, (state, action) => {
        state.result = action.payload
      })
      .addCase(deleteUnity.fulfilled, (state, action) => {
        state.result = action.payload
      })
      .addCase(getLessons.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.details = action.payload.details
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getSubLessons.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.details = action.payload.details
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getMicroLessons.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.details = action.payload.details
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getLesson.fulfilled, (state, action) => {
        state.selectedLesson = action.payload
      })
      .addCase(addLesson.fulfilled, (state, action) => {
        state.result = action.payload
      })
      .addCase(editLesson.fulfilled, (state, action) => {
        state.result = action.payload
      })
      .addCase(deleteLesson.fulfilled, (state, action) => {
        state.result = action.payload
      })
      .addCase(getPages.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.details = action.payload.details
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getPagesSearch.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.details = action.payload.details
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getPage.fulfilled, (state, action) => {
        state.selectedPage = action.payload
      })
      .addCase(addPage.fulfilled, (state, action) => {
        state.result = action.payload
      })
      .addCase(editPage.fulfilled, (state, action) => {
        state.result = action.payload
      })
      .addCase(editPageOrder.fulfilled, (state, action) => {
        state.result = action.payload
      })
      .addCase(deletePage.fulfilled, (state, action) => {
        state.result = action.payload
      })
      .addCase(getQuestions.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.details = action.payload.details
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getQuestionsSearch.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.details = action.payload.details
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getQuestion.fulfilled, (state, action) => {
        state.selectedQuestion = action.payload
      })
      .addCase(addQuestion.fulfilled, (state, action) => {
        state.result = action.payload
      })
      .addCase(editQuestion.fulfilled, (state, action) => {
        state.result = action.payload
      })
      .addCase(editQuestionOrder.fulfilled, (state, action) => {
        state.result = action.payload
      })
      .addCase(deleteQuestion.fulfilled, (state, action) => {
        state.result = action.payload
      })
      .addCase(deleteQuestionAnswer.fulfilled, (state, action) => {
        state.result = action.payload
      })
  }
})

export default appSlice.reducer
